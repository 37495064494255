import { useState } from "react";
import heroImg from "../imgs/MaristCollegeHero.jpg";
import { mod } from "../components/Utility";

function HeroImage() {
  return (
    <div className="relative text-center">
      <img
        className="max-h-fit overflow-x-hidden"
        src={heroImg}
        alt="Marist college Montagne Centre"
      ></img>
      <h2 className="breakwords absolute left-1/4 top-1/4 rounded-md bg-slate-700 bg-opacity-30 font-sans text-3xl text-white">
        FOR THOSE WHO LEAVE MCE, BUT FOR WHOM MCE NEVER LEAVES.
      </h2>
    </div>
  );
}

function Carosoul({ items }) {
  /* 
    On state change, fetch cur index
  */

  const [itemsIdx, setItemsIdx] = useState(0);

  const curItem = items[itemsIdx];

  function handleNext(direction) {
    setItemsIdx(mod(itemsIdx + direction, Object.keys(items).length));
  }

  return (
    <div className="flex-1-2 mx-12 my-6 h-32 justify-evenly rounded-lg bg-amber-200">
      <div></div>
      <button onClick={() => handleNext(-1)}>L</button>

      <div className="break-words px-5">
        <h4 className="text-wrap text-center">{curItem.title}</h4>
        <p className="flex-wrap text-center">{curItem.desc}</p>
      </div>

      <button className="" onClick={() => handleNext(1)}>
        R
      </button>
    </div>
  );
}

function HeroNews() {
  return (
    <div className="h-96 bg-white">
      <h3 className="pt-6 text-center text-2xl font-bold">Recent News</h3>
    </div>
  );
}

function HeroEvents() {
  return (
    <div className="h-48 bg-orange-50 px-8 ">
      <h3 className="pt-6 text-center text-2xl font-bold">
        Our Upcoming Events
      </h3>
      <p className="relative top-12">
        There are no upcoming events at this time.
      </p>
    </div>
  );
}

export default function Home() {
  return (
    <>
      <HeroImage></HeroImage>
      <HeroNews></HeroNews>
      <HeroEvents></HeroEvents>
    </>
  );
}
