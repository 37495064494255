import { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../imgs/MceLogo.png";
import "../App.css";

function NavButton({ href, children }) {
  return (
    <li className="hover:bg-slate-700 active:bg-sky-600">
      <Link className="p-1" to={href}>
        {children}
      </Link>
    </li>
  );
}

export function Title() {
  return (
    <div className="flex">
      <h1 className="">Marist College Eastwood</h1>
      <h1>Old Boys Association</h1>
    </div>
  );
}

function NavLogo() {
  return (
    <img className="h-16" src={Logo} alt="Marist College Eastwood Logo"></img>
  );
}

function DropDownNavButton({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  console.log("rednder");

  function handleOpen() {
    console.log(isOpen);
    setIsOpen(!isOpen);
  }

  return (
    <li>
      <button onClick={handleOpen} className="underline">
        test
      </button>
      {isOpen ? (
        <ul className="absolute bg-rose-500">
          <li>test1</li>
          <li>test1</li>
        </ul>
      ) : null}
    </li>
  );
}

function DonateButton() {
  return (
    <Link
      className="mx-2 rounded-sm bg-amber-500 px-3 py-2 text-stone-100 hover:bg-amber-400"
      to="/donate"
    >
      Donate &rarr;
    </Link>
  );
}

export function NavBar() {
  return (
    <nav className="sticky top-0 mr-0 flex content-center justify-center gap-8 bg-stone-800 p-4 text-lg text-stone-200">
      <NavLogo></NavLogo>
      <ul className="flex items-center justify-end gap-4">
        <NavButton href="/home">Home</NavButton>
        <NavButton href="/about">About</NavButton>
        <NavButton href="/alumni">Alumni</NavButton>
        <NavButton href="/news">News</NavButton>
        <DropDownNavButton></DropDownNavButton>
        <DonateButton></DonateButton>
      </ul>
    </nav>
  );
}
