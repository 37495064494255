// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// const ProductDisplay = () => (
//   <section>
//     <div className="product">
//       <img
//         src="https://i.imgur.com/EHyR2nP.png"
//         alt="The cover of Stubborn Attachments"
//       />
//       <div className="description">
//         <h3>Stubborn Attachments</h3>
//         <h5>$20.00</h5>
//       </div>
//     </div>
//     <form action="/create-checkout-session" method="POST">
//       <button type="submit">Checkout</button>
//     </form>
//   </section>
// );

// const Message = ({ message }) => (
//   <section>
//     <p>{message}</p>
//   </section>
// );

// export default App;
import React, { useState, useEffect } from "react";
import { NavBar, Title } from "./components/Navbar";
import "./App.css";

import About from "./pages/About";
import Home from "./pages/Home";
import { Route, Routes } from "react-router-dom";

function Footer() {}

const testCarosoulItems = [
  {
    title: "Testtitle1",
    desc: "My desgdfsgsdfhjsdfhjskdfgsdfdfnvmcbxvxnmcbnmvxcnmbcxnmbvnmcbvnmbcxnmvbxcnmbvcnmxbxcvnmbnmxcbvnmcxbmvxcnm 1",
  },
  { title: "capyvbara", desc: "babycara" },
];

export default function App() {
  const [message, setMessage] = useState("");

  // useEffect(() => {
  //   // Check to see if this is a redirect back from Checkout
  //   const query = new URLSearchParams(window.location.search);
  //   if (query.get("success")) {
  //     setMessage("Order placed! You will receive an email confirmation.");
  //   }
  //   if (query.get("canceled")) {
  //     setMessage(
  //       "Order canceled -- continue to shop around and checkout when you're ready."
  //     );
  //   }
  // }, []);

  return (
    <>
      <header>
        <Title></Title>
        <NavBar></NavBar>
      </header>
      <body>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/home" element={<Home />}></Route>
          <Route path="/about" element={<About />}></Route>
        </Routes>
      </body>
      <footer></footer>
    </>
  );

  // message ? <Message message={message} /> : <ProductDisplay />;
}
